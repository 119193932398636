<template>
    <div>
        <b-row>
            <b-col class="pt-1 pb-1">
                <b-button @click="shown" class="mr-2">
                    <font-awesome-icon icon="sync"/>
                </b-button>
                <b-button :href="'tel:'+getEventsCustomerData().mobile"
                          v-if="getEventsCustomerData().mobile"
                          class="mr-2"
                >{{ getEventsCustomerData().mobile }}
                </b-button>
                <b-button target="_blank"
                          :href="'https://api.whatsapp.com/send/?phone='+cleanPhone(getEventsCustomerData().mobile)"
                          v-if="getEventsCustomerData().mobile"
                          class="mr-2"
                >
                    <font-awesome-icon :icon="['fab', 'whatsapp']" class="text-success" size="lg"/>
                </b-button>
                <b-button
                    @click="$root.$children[0].openModal('email-modal', {documentId:customerId, docType:'customer_email'}, shown, {})"
                    v-if="getEventsCustomerData().email"
                    class="mr-2"
                    variant="warning"
                >
                    <font-awesome-icon class="mr-2" icon="envelope"/>
                    {{ $t('common.button.send_mail') }}
                </b-button>
                <b-button variant="primary"
                          class="mr-2"
                          @click="$root.$children[0].openModal('event-modal', {id: customerId}, shown, {width: '800px'})"
                >
                    <font-awesome-icon icon="plus"/>
                    {{ $t('common.event.button.create') }}
                </b-button>
                <b-button variant="primary"
                          class="mr-2"
                          @click="$root.$children[0].openModal('todo-modal', {customer_id:customerId}, shown)"
                >
                    <font-awesome-icon icon="plus"/>
                    {{ $t('common.button.add_todo') }}
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="3">
                <validation-observer tag="div" ref="observer">
                    <b-card style="position: sticky;top: 0px;">
                        <b-row>
                            <b-col>
                                <zw-select-group
                                    v-model="form.customer_status"
                                    :options="customerStatuses"
                                    name="customer_status"
                                    :label-prefix="labelPrefix"
                                    size="s"
                                ></zw-select-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col v-for="field in form.customFields"
                                   :key="field.name"
                                   v-if="field.options.show_in_activity"
                                   cols="12"
                            >
                                <custom-field v-model="form.customValues[field.name]"
                                              :model="form"
                                              :minDate="field.options.actual_dates == 1 ? currentDate() : ''"
                                              :readonly="field.options.readonly == 1 ? true : false"
                                              :isRequired="field.options.required == 1 ? true : false"
                                              :field="field"
                                />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-button variant="primary" @click="onSubmit" block>{{ $t('common.button.save') }}</b-button>
                            </b-col>
                        </b-row>
                    </b-card>
                </validation-observer>
            </b-col>
            <b-col cols="9">
                <zw-events-new :events="getEvents()"
                               :callback="shown"
                               :customerId="customerId"
                ></zw-events-new>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import ValidateMixin from "@/mixins/validate";
import ZwEventsNew from "@/components/ZwEventsNew";
import moment from "moment/moment";

export default {
    name: 'customer-events',
    components: {ZwEventsNew},
    mixins: [ValidateMixin, commonSave],
    props: {
        customerId: {
            type: Number,
        },
        sidebar: {}
    },
    data() {
        return {
            loading: false,
            labelPrefix: 'customer.label.',
            defaultForm: {},
            form: {}
        }
    },
    mounted() {
        this.shown()
    },
    methods: {
        ...mapGetters('CommonData', ['getEntityStatuses']),
        ...mapGetters('Customer', ['getCustomer', 'getEvents', 'getEventsCustomerData']),
        shown() {
            const eventsFetch = this.$store.dispatch('Customer/fetchEvents', this.customerId)
            this.$store.dispatch('Customer/fetchCustomer', this.customerId)
                .then(() => {
                    this.form = JSON.parse(JSON.stringify({...this.defaultForm, ...this.getCustomer()}))

                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        cleanPhone(mobile) {
            let cleaned = '';
            if (mobile) {
                cleaned = mobile.replace(/[^\d+]/g, '');
                if (cleaned.startsWith('+')) {
                    cleaned = cleaned.substring(1);
                }
            }

            return cleaned;
        },
        currentDate() {
            const today = moment();
            return today.format('YYYY-MM-DD');
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    let formData = JSON.parse(JSON.stringify(this.form))

                    this.$store.dispatch('Customer/saveCustomer', formData)
                        .then((response) => {
                            this.shown()
                        })
                        .catch(errors => {
                            if (errors) {
                                this.loading = false
                                this.$refs['observer'].setErrors(errors)
                            }
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    },
    computed: {
        customerStatuses() {
            const customerStatuses = this.getEntityStatuses()['customer'] || []
            let list = {}
            customerStatuses.map(val => {
                list[val.id] = val.name
            })

            return list;
        }
    },
}
</script>